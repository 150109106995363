html,
body {
    padding: 0;
    margin: 0;
    font-family: Roboto, sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

/* Bunch of daypilot hacks */

.calendar_default_main {
    border: none !important;
}

.calendar_default_colheader_inner {
    background-color: unset !important;
    border-right: unset !important;
}

.calendar_default_main tr tr > td:nth-of-type(6) > div > div, .calendar_default_main tr tr > td:nth-of-type(7) > div > div {
    color: rgb(229, 115, 115) !important;
}

.calendar_default_corner_inner, .calendar_default_cornerright_inner {
    background-color: unset !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-right: unset !important;
}

.calendar_default_event {
    /* width: 33.3333% !important; */
}

.calendar_default_event_inner {
    border-radius: 2px;
    border-color: #BBB !important;
    padding: 2px 2px 2px 4px !important;
    font-size: 10px !important;
    overflow-wrap: anywhere;
}

.calendar_default_rowheader_minutes {
    font-size: 16px !important;
    vertical-align: unset !important;
    padding-left: 2px;
    padding-right: 2px;
}

.calendar_default_rowheader_inner {
    background-color: unset !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px !important;
}

/* End of bunch of daypilot hacks */

/* poppins-300 - latin */
@font-face {
    font-display: swap;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    src: url("../../public/fonts/poppins-v22-latin-300.woff2") format("woff2");
}
/* poppins-regular - latin */
@font-face {
    font-display: swap;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    src: url("../../public/fonts/poppins-v22-latin-regular.woff2") format("woff2");
}
/* poppins-600 - latin */
@font-face {
    font-display: swap;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    src: url("../../public/fonts/poppins-v22-latin-600.woff2") format("woff2");
}
/* roboto-100 - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    src: url("../../public/fonts/roboto-v47-latin-100.woff2") format("woff2");
}
/* roboto-100italic - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: italic;
    font-weight: 100;
    src: url("../../public/fonts/roboto-v47-latin-100italic.woff2") format("woff2");
}
/* roboto-200 - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 200;
    src: url("../../public/fonts/roboto-v47-latin-200.woff2") format("woff2");
}
/* roboto-200italic - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: italic;
    font-weight: 200;
    src: url("../../public/fonts/roboto-v47-latin-200italic.woff2") format("woff2");
}
/* roboto-300 - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: url("../../public/fonts/roboto-v47-latin-300.woff2") format("woff2");
}
/* roboto-300italic - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: italic;
    font-weight: 300;
    src: url("../../public/fonts/roboto-v47-latin-300italic.woff2") format("woff2");
}
/* roboto-regular - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: url("../../public/fonts/roboto-v47-latin-regular.woff2") format("woff2");
}
/* roboto-italic - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    src: url("../../public/fonts/roboto-v47-latin-italic.woff2") format("woff2");
}
/* roboto-500 - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: url("../../public/fonts/roboto-v47-latin-500.woff2") format("woff2");
}
/* roboto-500italic - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: italic;
    font-weight: 500;
    src: url("../../public/fonts/roboto-v47-latin-500italic.woff2") format("woff2");
}
/* roboto-600 - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    src: url("../../public/fonts/roboto-v47-latin-600.woff2") format("woff2");
}
/* roboto-600italic - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: italic;
    font-weight: 600;
    src: url("../../public/fonts/roboto-v47-latin-600italic.woff2") format("woff2");
}
/* roboto-700 - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: url("../../public/fonts/roboto-v47-latin-700.woff2") format("woff2");
}
/* roboto-700italic - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    src: url("../../public/fonts/roboto-v47-latin-700italic.woff2") format("woff2");
}
/* roboto-900 - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    src: url("../../public/fonts/roboto-v47-latin-900.woff2") format("woff2");
}
/* roboto-900italic - latin */
@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    src: url("../../public/fonts/roboto-v47-latin-900italic.woff2") format("woff2");
}
